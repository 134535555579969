<template>
    <v-container
        fill-height
    >
        <v-alert
            color="red"
            type="error"
            v-if="error.length > 0"
        >
            <ul>
                <li
                    v-for="(item, index) in error"
                    :key="'error' + index">
                    {{ item }}
                </li>
            </ul>
        </v-alert>

        <v-row v-if="!isResultPdfGenerated">
            <v-card
                class="mx-auto my-12"
                max-width="500"
                height="180"
            >
                <v-card-text>
                    <p class="text-h5 font-weight-bold teal--text text--darken-1 text-center">
                        集計PDFを生成しています..
                    </p>
                    <p class="text-h5 teal--text text--darken-1 d-block text-center">
                        計算量によっては時間がかかります。<br/>
                        途中でタブを閉じないで下さい。
                    </p>
                    <v-progress-linear
                        indeterminate
                        color="primary"
                        class="mb-0 mt-2"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-row>

        <v-row v-else
            class="my-16">
            <v-col>
                <v-row
                    class="pb-5"
                    justify="center"
                    align-content="center"
                >
                    <p class="text-h5 font-weight-bold teal--text text--darken-1 text-center">
                        集計PDFのご用意ができました。
                    </p>
                </v-row>
                <v-row
                    class="pb-5"
                    justify="center"
                    align-content="center"
                    v-if="version== 'BETA version'"
                >
                    <p class="teal--text text--darken-1 text-center">
                        β版の集計PDFは、集計結果のみ出力します。アップロードしたPDFファイルは連結しません。
                    </p>
                </v-row>
                <v-row
                    justify="center"
                    align-content="center"
                >
                    <v-btn
                        @click="downloadPdf"
                        rounded-lg
                        color='#118C66'
                        width="300" height="50"
                        class="white--text font-weight-bold text-h6 mb-2"
                    >
                        集計PDFダウンロード
                    </v-btn>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="pl-5 pr-5">
            <v-btn
            to="total-result"
            rounded-lg color="#A0A0A0"
            width="150"
            height="60"
            class="mt-15 white--text font-weight-bold text-h6"
            >
                <v-icon large>mdi-chevron-left</v-icon>
                <v-spacer></v-spacer>
                戻る
                <v-spacer></v-spacer>
            </v-btn>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters,mapActions } from 'vuex'

export default {
    mounted () {
        this.update({
            resultPdf: null,
            isResultPdfGenerated: false
        })

        this.clearError()
        // 集計pdfデータを取得する
        this.generatePdf()
    },

    computed: {
        ...mapGetters({version:'getVersion'}),
        ...mapGetters(['error']),
        ...mapGetters('result', {
            resultPdf: 'getResultPdf',
            isResultPdfGenerated: 'getIsResultPdfGenerated'
        }),
    },

    methods: {
        ...mapActions(['clearError']),
        ...mapActions('result', [
            'update',
            'generateResultPdf'
        ]),

        async generatePdf() {
            // 集計pdfデータを取得する
            await this.generateResultPdf()
            if (this.resultPdf) this.update({isResultPdfGenerated: true})
        },

        // 集計pdfをダウンロードする
        downloadPdf() {
            const blob = new Blob([this.resultPdf] , { type: 'application/pdf' })
            const filename = 'result.pdf'

            //IE10/11用(download属性が機能しないためmsSaveBlobを使用）
            if (window.navigator.msSaveBlob) {
                window.navigator.msSaveBlob(blob, filename)
            //その他ブラウザ
            } else {
                const fileURL = window.URL.createObjectURL(blob)
                let fileLink = document.createElement('a')

                fileLink.href = fileURL
                fileLink.setAttribute('download', filename)
                document.body.appendChild(fileLink)

                fileLink.click()
            }
        }
    }
}
</script>